
.notification{
    display: flex;
    box-sizing: border-box;
    background-color: var(--mainColor);
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 5px 30px;
}


.notification .maxWidthHolder{
    /*max-width: var(--maxWidthPlus);*/
    width: 100%;
    display: flex;
}

.text{
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    color: white;
    font-size: 15px;
    font-weight: 500;
}

.actions{
    display: flex;
    align-items: center;
    justify-content: center;
}

.subText{
    color: white;
    opacity: 0.8;
    font-size: 12px;

}
