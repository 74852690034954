
.wrap{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    transition: 0.2s;
    width: 100%;
    min-width: 45px;
}

.wrap .head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--darkAccents-7);
}

.wrap.horizontal .head{
    border-bottom: none;
    margin-bottom: 10px;
    margin-top: 20px;
}

.wrap .head .title{
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrap .head .title h2{
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    margin:0;
}

.wrap .head .add{
    display: flex;
    align-items: center;
    justify-content: center;
}

.groupsWrap{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    position: relative;
    scroll-behavior: smooth;
}

.loading{

}

.groups{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    position: relative;
    scroll-behavior: smooth;
}

.groups .add{
    display: flex;
    height: 34px;
    align-items: center;
    justify-content: center;
}

.wrap .leftArrow{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    padding: 10px;
    background: var(--darkAccents-6);
    border-radius: 50%;
    transition: 0.2s;
    /*width: 20px;*/
    height: 40px;
    width: 40px;
    z-index: 20;
    cursor: pointer;
}

.wrap .leftArrow:hover{
    color: white;
    background: var(--mainColor)
}

.wrap .rightArrow{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    background: var(--darkAccents-6);
    border-radius: 50%;
    transition: 0.2s;
    height: 40px;
    width: 40px;
    z-index: 20;
}

.wrap .rightArrow:hover{
    color: white;
    background: var(--mainColor)
}


.group{
    padding: 5px 10px;
    border-radius: 0;
    display: flex;
    align-items: center;
    transition: 0.3s;
    position: relative;
}

.groupHorizontal{
    padding: 5px 10px;
    border-radius: 0;
    display: flex;
    align-items: center;
    transition: 0.3s;
    position: relative;
}

.groupHorizontal.all .icon{
    border: none;
}



.group:hover{
    cursor: pointer;
    background-color: var(--darkAccents-7);
}

.group.selected{
    background-color: var(--mainColor);
    color: white;
}



.group.selected .nameWrap{
    font-weight: 600;
}

.group.selected .nameWrap{
    color: white;
}

.small .all{
    margin-top: 20px;
}

.all .icon{
    text-transform: uppercase;
    background-color: transparent;
}

.icon{
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    background-color: transparent;
    border-radius: 50%;
    border: 2px solid var(--text-base);
    margin-right: 10px;
    font-size: 11px;
    text-transform: uppercase;
    line-height: 13px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon span{
    color: var(--text-base);
    line-height: 13px;
    font-size: 13px;
    display: flex;
    transition: 0.2s;
}

.selected .icon span{
    color: var(--text-base)
}

.group.selected .icon span{
    color: white;
}


/* .group .icon .iconBorder{*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    border: 2px solid white;*/
/*    border-radius: 50%*/
/*}*/

.group.noDelete .icon{
    border: none;
}

.group .nameWrap{
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.groupHorizontal .nameWrap{
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.groupHorizontal.selected .nameWrap{
    font-weight: 600;
}

.nameEditWrap{
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
}

.group .nameWrap .editMode{
    display: flex;
    flex: 1;
    width: 100%;
}

.action{
    display: flex;
    z-index: 10;
    flex-direction: row;
    justify-content: flex-end;
    transition: 0.2s background;
    border-radius: 4px;
    cursor: pointer;
}

.nameEditWrap.tooltip .action{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

}
.group:hover .action{
    display: flex;
}
.wrap.horizontal .group:hover .action{
    background: var(--darkAccents-8);
}

.action .actionTrigger{
    display: flex;
    padding: 3px;
    transition: 0.2s;
    color: var(--darkAccents-4);
}

.wrap.horizontal .groups .groupHorizontal.button{
    background: var(--newInfoColorUltraLight);
    border: 1px dashed var(--newInfoColorLight);
    color: var(--newInfoColor);
    /*border:none;*/
    min-width: inherit;
    padding: 7px 20px;
    transition: 0.2s;
    border-radius: 50px;
    /*padding: 10px;*/

}

.wrap.horizontal .groups .groupHorizontal.button .nameWrap{
    font-weight: 600;
    line-height: 16px;
    font-size: 16px;
}

.wrap.horizontal .groups .groupHorizontal.button .icon{
    border:none;
    margin: 0;
    padding:0;
    background:none;
}

.wrap.horizontal .groups .groupHorizontal.button:hover{
    background: var(--mainColor);
    border: 1px solid var(--newInfoColorLight);
    color: white;
}

.wrap.horizontal .groups .group .nameWrap{
    font-size: 16px;
}


.wrap ::-webkit-scrollbar {
    display: none;
}

/*!* Track *!*/
/*.wrap ::-webkit-scrollbar-track {*/
/*    width: 8px;*/
/*    border-radius: 8px;*/
/*    background: transparent;*/
/*}*/

/*!* Handle *!*/
/*.wrap ::-webkit-scrollbar-thumb {*/
/*    border-radius: 8px;*/
/*    background: var(--darkAccents-6);*/
/*    transition: 0.2s;*/
/*}*/

/*!* Handle on hover *!*/
/*.wrap ::-webkit-scrollbar-thumb:hover {*/
/*    background: #555;*/
/*}*/

.wrap.horizontal{
    height: inherit;
}

.wrap.horizontal .head{
    padding: 0;
}
.wrap.horizontal .groups{
    overflow: auto !important;
    flex-direction: row;
    border-radius: 30px;
}

.wrap.horizontal .groups .groupHorizontal{
    margin: 0 10px 0 0;
    min-width: 180px;
    padding: 7px 7px;
    border-radius: 20px;
    background: var(--backgroundColor);
    border: 1px solid var(--darkAccents-7);
}


.wrap.horizontal .groupHorizontal:hover{
    cursor: pointer;
    background: var(--darkAccents-8);
}
.wrap.horizontal .groupHorizontal.selected{
    background: var(--darkAccents-8);
    /*color: var(--text-secondary);*/
}

.footerNav{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid var(--darkAccents-8);
}

.footerNav .footerNavItem{
    padding: 10px 5px;
    /*background-color: red;*/
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--text-base);
    font-weight: 600;
    justify-content: center;
    transition: 0.3s;
}

.footerNav .footerNavItem.active{
    background-color: var(--backgroundColorLight);
    color: var(--text-secondary)
}

.footerNav .footerNavItem:hover{
    cursor: pointer;
    background-color: var(--darkAccents-8);
}

.footerNav .footerNavItem.active:hover{
    background-color: var(--backgroundColorLight);
}

.wrap.small:not(.horizontal) .group .icon{
    margin-right: 0;
}
/*.wrap.small:not(.horizontal) .group .nameWrap{*/
/*    display: none;*/
/*}*/


.smallModeSwitch{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    color: var(--text-base);
}

.smallModeSwitch:hover{
    cursor: pointer;
    background-color: var(--darkAccents-6);
}

.smallModeSwitch .arrowWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    transition: cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
}

.smallModeSwitch.right .arrowWrap{
    transform: rotate(180deg);
}


@media (max-width: 1100px){
    .wrap.horizontal .head .add{
        display: initial;
    }

    .footerNav .footerNavItem span{
        display: none;
    }
}
