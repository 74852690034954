.wrap{
    display: flex;
    width: 100%;
}


.itemHolder{
    width: 100%;
    display: grid;
    grid-gap: 15px;
    grid-template: 1fr / 1fr;
    flex-direction: column;
}

.itemHolder .item{
    background-color: var(--backgroundColor);
    padding: 15px;
    border: 1px solid var(--darkAccents-7);
    border-radius: 10px;
    display: flex;
    transition: all 0.3s ease;
    cursor: pointer;
}

.itemHolder .item:hover{
    background-color: var(--darkAccents-7);
}

.itemHolder .item.disabled{
    background-color: var(--darkAccents-8);
    cursor: not-allowed;
}

.itemHolder .item.done .icon{
    background: var(--greenLight);
    color: white;
}



.itemHolder .item:last-child{
    margin-bottom: 0;
    border-bottom: none;
}


.itemHolder .item .icon{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    min-height:25px;
    min-width: 25px;
    margin-right: 10px;
    background: var(--darkAccents-6);
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}

.itemHolder .loader{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.itemHolder .item .text .title{
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    color: var(--text-base);
    margin-bottom: 5px;
}

.itemHolder .item .text .links{
    display: flex;
}

.itemHolder .item .text .link{
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-top: 3px;
    /*justify-content: center;*/
    line-height: 13px;
    transition: 0.2s;
    margin-right: 10px;
    color: var(--darkAccents-4);
}
.itemHolder .item .text .link:last-child{
    margin-right: 0;
}
.itemHolder .item .text .link:hover{
    text-decoration: underline;
    color: var(--mainColor);
}

.itemHolder .links .link .linkIcon{
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.itemHolder .item .text .link .name{
    line-height: 13px;
}

