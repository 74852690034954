.wrap{
    display: flex;
    width: 100%;
    padding: 60px;
    box-sizing: border-box;
    justify-content: center;
}

.welcome{
    width: 100%;
    max-width: var(--maxWidthPlusPlus);
    display: flex;
}

.main{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.side{
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    max-width: 350px;
    flex: 1;
    background: var(--darkAccents-9);
    border: 1px solid var(--darkAccents-7);
    padding: 15px;
    border-radius: 10px;
}

.side .title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    flex: 1;
    margin-bottom: 20px;
    width: 100%;
}

.grid .item{
    padding: 20px;
    background: var(--backgroundColor);
    border: 1px solid var(--darkAccents-7);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.grid .item .top{
    display: flex;
    margin-bottom: 20px;
    width: 100%;
}

.grid .item .top .icon{
    width: 60px;
    min-width: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 60px;
}

.grid .item .top .content{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.grid .item .top .content .title{
    font-size: 17px;
    line-height: 18px;
    margin-bottom: 5px;
    font-weight: 600;
}

.grid .item .top .content .description{
    font-size: 14px;
    color: var(--darkAccents-4);
}

.grid .item .action{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    width: 100%;
    font-weight: 600;
    box-sizing: border-box;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 18px;
    border: 2px solid var(--mainColor);
    border-radius: 10px;
    transition: 0.2s;
    color: var(--mainColor);

}

.grid .item .action:hover{
    background: var(--mainColor);
    color: white;
}


.blog{
    margin-top: auto;
    padding: 20px;
    background: var(--darkAccents-9);
    border-radius: 10px;
    border: 1px solid var(--darkAccents-7);
    display: flex;
    flex-direction: column;
}

.blog .title{
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}

.blog .articles{
    overflow: auto;
    display: flex;
    flex-direction: row;
}

.blog .articles .article{
    margin-right: 20px;
    border: 1px solid var(--darkAccents-7);
    border-radius: 10px;
    background: var(--backgroundColor);
    flex: 1;
    display: flex;
    transition: 0.2s;
    flex-direction: column;
}

.blog .articles .article:hover{
    background: var(--darkAccents-8);
}

.blog .articles .article .head{
    border-radius: 10px;
    /*margin: 10px;*/
    height: 150px;
    overflow: hidden;
}

.blog .articles .article .content{
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.blog .articles .article:last-child{
    margin-right:0;
}

.blog .articles .article .img{
    width: 100%;
    height: 100%;
    /*background: var(--darkAccents-5);*/
    /*border-radius: 10px;*/
    /*border-bottom-left-radius: 20px;*/
    /*border-bottom-right-radius: 20px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
}

.blog .articles .article .articleTitle{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--text-base);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog .articles .article .articleDescription{
    font-size: 12px;
    color: var(--darkAccents-4);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


.checkList{
    flex: 1;
}

.freeTrial{
    box-sizing: border-box;
    max-width: var(--maxWidthPlus);
    width: 100%;
    border-radius: 8px;
    /*border: 1px solid var(--darkAccents-6);*/
    background-color: rgb(121, 40, 202);
    margin-bottom: 40px;
    color: white;
    padding: 20px;
    display: flex;
    transition: all 0.3s ease;
    /*background-color: var(--backgroundColor);*/
    cursor: pointer;
}

.freeTrial:hover{
    /*border: 1px solid var(--mainColor);*/
    background-color: var(--mainColor);
}

.freeTrial .text{
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
}

.freeTrial h2{
    margin: 0;
    font-weight: 700;
    color: white;
    font-size: 38px;
}

.freeTrial .description{
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--darkAccents-6);
}

.freeTrial .countdownWrap{
    flex-direction: column;
    display: flex;
    align-items: flex-end;
    text-align: right;
}

.freeTrial .countdownWrap .countdown{
    font-size: 50px;
    line-height: 50px;
    font-weight: 600;
}

.freeTrial .countdownWrap .title{
    font-size: 18px;
    color: var(--accents-4);
    transition: 0.3s;
}

.freeTrial:hover .countdownWrap .title{
    font-size: 18px;
    color: var(--accents-4);
}

.notification{
    display: flex;
    box-sizing: border-box;
    background-color: var(--mainColor);
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 5px 30px;
}


.notification .maxWidthHolder{
    max-width: var(--maxWidthPlus);
    width: 100%;
    display: flex;
}

.text{
    flex: 1;
    display: flex;
    align-items: center;
    color: white;
    font-weight: 500;
}

.actions{
    display: flex;
    align-items: center;
    justify-content: center;
}


.fullTour{
    padding: 15px 10px;
    margin-top: auto;
    display: flex;
    /*align-items: center;*/
    /*justify-content: center;*/
    transition: 0.2s;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    background: var(--backgroundColor);
    border-radius: 10px;
    border: 1px solid var(--darkAccents-6);

}

.fullTour:hover{
    background: var(--darkAccents-8 );
    border-radius: 10px;
}

.fullTour .left{
    border-radius: 50%;
    border: 3px solid var(--mainColor);
    padding: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 7px;
    color: var(--mainColor);
    width: 40px;
    height: 40px;
}
.fullTour .left svg{
    margin-left: 5px;
}

.fullTour .right{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.fullTour .right .title{
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    color: var(--text-base);
    margin-bottom: 5px;
}

.fullTour .right .description{
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: var(--darkAccents-4);
}
